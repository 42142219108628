import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  cargandoUsuarioLogueadoSelector,
  usuarioLogueadoSelector,
} from 'store/selectors';
import useWindowSize from 'hooks/useWindowSize';

import * as ROUTES from 'constants/RouterConstants';
// Iconos
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from '@mui/icons-material/Email';
import iconoNivelIntermedio from 'assets/icons/basico_intermedio_sin_fondo.svg';

import BANDERA_BRASIL from 'assets/bandera-brasil.png';
import BANDERA_ARGENTINA from 'assets/bandera-argentina.png';
import BANDERA_URUGUAY from 'assets/bandera-uruguay.png';

// Constantes
import {
  ANCHO_ESCRITORIO,
  INFO_ICONO_NIVEL_SEGURIDAD,
  CARD_SEGURIDAD_TITLE,
} from 'constants/commonConstants';

// Componentes
import Card from 'components/Card';
import CardSpinner from 'components/CardSpinner';
import TitleCard from 'components/TitleCard';

// Utils
import {
  esCiudadanoTemporal,
  correoElectronico,
  origenRegistro,
} from './utils';

// Estilos
import styles from './autogestionIdp.module.css';

const AutogestionIdp = () => {
  const { t, i18n } = useTranslation();

  const usuarioLogueado = useSelector(usuarioLogueadoSelector);
  const cargandoUsuarioLogueado = useSelector(cargandoUsuarioLogueadoSelector);

  const [ancho] = useWindowSize();
  const esMovil = ancho < ANCHO_ESCRITORIO;

  const nivel = usuarioLogueado?.nivel;
  const nivelTexto = nivel?.substring(
    nivel.indexOf(' '),
    nivel.lastIndexOf('('),
  );

  useEffect(() => {
    if (usuarioLogueado && origenRegistro(usuarioLogueado) === 'Gov BR') {
      i18n.changeLanguage('pt');
    } else i18n.changeLanguage('es');
  }, [usuarioLogueado, i18n]);

  const obtenerBandera = () => {
    const pais = usuarioLogueado?.documento.pais_emisor.nombre;
    if (pais === 'Argentina') return BANDERA_ARGENTINA;
    if (pais === 'Brasil') return BANDERA_BRASIL;
    if (pais === 'Uruguay') return BANDERA_URUGUAY;
    return null;
  };

  const obtenerNombreBandera = () => {
    const pais = usuarioLogueado?.documento.pais_emisor.nombre;
    return `Bandera-${pais}`;
  };

  const camposDatosPrimerFila = () => ({
    [t('nombreYApellido')]: {
      texto: usuarioLogueado?.nombre_completo || t('sinDatos'),
      icono: <AccountBoxIcon />,
    },
    [t('nivel')]: {
      texto: nivelTexto || t('sinDatos'),
      icono: (
        <img
          className={styles.iconoNivelSeguridad}
          src={iconoNivelIntermedio}
          alt={INFO_ICONO_NIVEL_SEGURIDAD}
        />
      ),
    },
    [t('email')]: {
      texto: correoElectronico(usuarioLogueado) || t('sinDatos'),
      icono: <EmailIcon />,
    },
    [t('pais')]: {
      texto: usuarioLogueado?.documento.pais_emisor.nombre || t('sinDatos'),
      icono: <PublicIcon />,
    },
    [t('tipoDeDocumento')]: {
      texto: usuarioLogueado?.documento.tipo_documento.nombre || t('sinDatos'),
      icono: <BadgeIcon />,
    },
    [t('documento')]: {
      texto: usuarioLogueado?.numero_documento || t('sinDatos'),
      icono: <BadgeIcon />,
    },
  });

  if (cargandoUsuarioLogueado) {
    return <CardSpinner spin className="spinner__transparent" />;
  }

  return (
    <div className={styles.contenedorPrincipal}>
      <TitleCard
        className={styles.titulo}
        title={t('title', { idp: origenRegistro(usuarioLogueado) })}
      />
      <Card innerClassName={styles.card}>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.contenedorNombre}>
            <PersonIcon />
            {t('informacionPersonal')}
          </div>
          <div
            className={
              esMovil ? styles.contenedorDatosMovil : styles.contenedorDatos
            }
          >
            {Object.entries(camposDatosPrimerFila()).map(([key, value]) => (
              <div key={key} className={styles.contenedorDato}>
                <div className={styles.textoIcono}>
                  {value.icono}
                  <span className={styles.campo}>{key}</span>
                </div>
                <div className={styles.contenedorBanderaPais}>
                  {key === t('pais') &&
                    usuarioLogueado?.documento.pais_emisor.nombre && (
                      <img
                        className={styles.bandera}
                        src={obtenerBandera()}
                        alt={obtenerNombreBandera()}
                      ></img>
                    )}
                  <p
                    className={
                      key === t('pais') &&
                      usuarioLogueado?.documento.pais_emisor.nombre
                        ? styles.campoValorPais
                        : styles.campoValor
                    }
                  >
                    {value.texto}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>

      {!esCiudadanoTemporal(usuarioLogueado) && (
        <div className={styles.linkSeguridad}>
          <Link to={ROUTES.SEGURIDAD_ROUTE}>{CARD_SEGURIDAD_TITLE}</Link>
        </div>
      )}
    </div>
  );
};

export default AutogestionIdp;
