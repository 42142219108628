/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

import OIDCService from 'utils/OIDCService';
import { fetchClient, acceptConsent } from 'actions/consent';
import CardSmall from 'components/CardSmall';
import ButtonSection from 'components/ButtonSection';
import CardSpinner from 'components/CardSpinner';
import { showExternalHeader } from 'actions/ui';

import { CONSENT_TITLE } from 'constants/pageTitles';
import {
  ConsentIntro,
  ConsentScopeList,
  ConsentTermsAndCondition,
} from './consent';

class ConsentContainer extends React.Component {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.props.showExternalHeader();
    const parsed = queryString.parse(this.props.location.search);
    this.props.fetchClient(parsed.client_id, parsed.scope);
  }

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  handleClick(allow = false) {
    this.setState({ loading: true });
    const { client_id, next } = queryString.parse(this.props.location.search);
    const data = {
      client_id,
      next,
      allow,
    };
    OIDCService.getInstance().handleConsent(data);
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------
  render() {
    if (this.props.loading || this.state.loading) {
      return <CardSpinner spin />;
    }
    return (
      <>
        <Helmet title={CONSENT_TITLE} />
        <CardSmall title="Autorizar aplicación">
          <ConsentIntro
            client_name={this.props.client.name}
            client_url={this.props.client.website_url}
          />
          <ConsentScopeList scopes={this.props.client.scopes} />
          <ConsentTermsAndCondition
            client_name={this.props.client.name}
            terms_url={this.props.client.terms_url}
          />
          <ButtonSection
            textPrimary="Permitir"
            handleClickPrimary={() => {
              this.handleClick(true);
            }}
            textSecondary="Cancelar"
            handleClickSecondary={() => {
              this.handleClick(false);
            }}
            style={{ marginTop: '32px' }}
          />
        </CardSmall>
      </>
    );
  }
}

const mapStateToProps = state => ({
  client: state.brokerOidc.client,
  loading: state.brokerOidc.loading,
});

export default connect(mapStateToProps, {
  fetchClient,
  acceptConsent,
  showExternalHeader,
})(ConsentContainer);
