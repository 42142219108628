/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import CardSuccess from 'components/CardSuccess';
import ButtonLink from 'components/ButtonLink';
import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { clearRegistroErrors } from 'actions/registro';
import { resendActivationEmailLogged } from 'actions/seguridad';
import Auth from 'utils/Auth';
import {
  CONTACTO_ROUTE,
  RESENDED_ACTIVATION_EMAIL_LOGGED,
  REGISTRO_REENVIAR_CORREO_ACTIVACION,
} from 'constants/RouterConstants';
import { SIGNUP_LINK_SENT_TITLE } from 'constants/pageTitles';
import { NO_RECIBISTE_CORREO } from 'constants/commonConstants';

class RegistroMailEnviadoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleReenviarActivacionCuenta =
      this.handleReenviarActivacionCuenta.bind(this);
  }

  componentDidMount() {
    this.props.clearRegistroErrors();
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(SIGNUP_LINK_SENT_TITLE);
  }

  componentWillUnmount() {
    this.props.showInternalHeader();
  }

  handleReenviarActivacionCuenta(e) {
    e.preventDefault();
    if (this.props.isAuthenticated) {
      this.props.resendActivationEmailLogged(Auth.getInstance().getUserId());
      this.props.history.push(RESENDED_ACTIVATION_EMAIL_LOGGED);
    } else {
      this.props.history.push(REGISTRO_REENVIAR_CORREO_ACTIVACION);
    }
  }

  render() {
    return (
      <>
        <Helmet title={SIGNUP_LINK_SENT_TITLE} />
        <CardSuccess
          title="Tu usuario fue creado correctamente"
          description="Para continuar, verificá tu cuenta con el correo electrónico
                       que enviamos a tu casilla."
          extra1="Puede encontrarse entre los correos no deseados (SPAM)."
          extra2={
            <div>
              <p>
                {NO_RECIBISTE_CORREO}
                <ButtonLink
                  title="Solicitá uno nuevo »"
                  onClick={this.handleReenviarActivacionCuenta}
                />
              </p>
              <p>
                ¿Estás teniendo problemas?
                <ButtonLink
                  title="Comunicate con nosotros »"
                  to={CONTACTO_ROUTE}
                />
              </p>
            </div>
          }
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.mis_datos.currentUser,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  clearRegistroErrors,
  resendActivationEmailLogged,
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
})(RegistroMailEnviadoContainer);
