/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import CardSmall from 'components/CardSmall';
import CardSpinner from 'components/CardSpinner';
import ButtonSection from 'components/ButtonSection';
import { MFA_PAGE_ROUTE } from 'constants/RouterConstants';
import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { fetchMFA } from 'actions/mfa';
import { MFA_VERIFY_CODE_TOTP_SUCCESS_TITLE } from 'constants/pageTitles';
import {
  MFA_TOTP_VERIFIED_TITLE,
  MFA_TOTP_VERIFIED_DESC,
} from 'constants/commonConstants';
import Auth from 'utils/Auth';

class TOTPVerificarCodigoExitoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(MFA_VERIFY_CODE_TOTP_SUCCESS_TITLE);
    this.props.fetchMFA(Auth.getInstance().getUserId());

    if (!this.props.totp) {
      this.props.history.push(MFA_PAGE_ROUTE);
    }
  }

  componentWillUnmount() {
    this.props.showInternalHeader();
  }

  handleCallback() {
    this.props.history.push(MFA_PAGE_ROUTE);
  }

  render() {
    if (!this.props.totp) {
      return <CardSpinner spin />;
    }
    return (
      <>
        <Helmet title={MFA_VERIFY_CODE_TOTP_SUCCESS_TITLE} />
        <CardSmall
          title={MFA_TOTP_VERIFIED_TITLE}
          desc={MFA_TOTP_VERIFIED_DESC}
        >
          <div style={{ marginTop: '24px' }}>
            <ButtonSection
              textPrimary="Finalizar"
              handleClickPrimary={this.handleCallback}
              isVertical
            />
          </div>
        </CardSmall>
      </>
    );
  }
}
const mapStateToProps = state => ({
  totp: state.mfa.methods.totp,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
  fetchMFA,
})(TOTPVerificarCodigoExitoContainer);
